import RoutesDefinition from './Shell/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme } from './Shell/theme';
import Paper from '@mui/material/Paper';
export default function App() {
    return (
        <Paper elevation={0} id="AppWrapper">
            <ThemeProvider theme={lightTheme}>
                <CssBaseline/>
                <Router>
                    <RoutesDefinition />
                </Router>
            </ThemeProvider>
        </Paper>
    );
};