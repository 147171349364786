import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import AuthService from "../Services/AuthService";

// Lazy Components
const Home = lazy(() => import('../Pages/Home'));
const Welcome = lazy(() => import('../Access/Welcome'));
const Topics = lazy(() => import('../Pages/Topics'));
const Account = lazy(() => import('../Pages/Account'));
const Favorites = lazy(() => import('../Pages/Favorites'));
const Contactus = lazy(() => import('../Pages/Contactus'));
const ChangePassword = lazy(() => import('../Pages/ChangePassword'));
const MyProfile = lazy(() => import('../Pages/MyProfile'));
const MyLearning = lazy(() => import('../Pages/MyLearning/MyLearning'));
const Videos = lazy(() => import('../Pages/Videos'));
const PDFs = lazy(() => import('../Pages/PDFs'));
const Glossary = lazy(() => import('../Pages/Glossary'));
const Manufacturer = lazy(() => import('../Pages/MyLearning/Manufacturer'));
const Level = lazy(() => import('../Pages/MyLearning/Level'));
const OjtPending = lazy(() => import('../Pages/MyLearning/OjtPending'));
const Video = lazy(() => import('../Pages/MyLearning/Video'));
const Quiz = lazy(() => import('../Pages/MyLearning/Quiz'));
const Forgot = lazy(() => import('../Access/Forgot'));
const Login = lazy(() => import('../Access/Login'));
const NotFound = lazy(() => import('../Components/NotFound'));
const FallbackLoader = lazy(() => import('../Components/FallbackLoader'));
const OjtForm = lazy(() => import('../Pages/OjtForm'));
export default function RoutesDefinition(props) {
    return (
        <Suspense fallback={<FallbackLoader allowimage={true} allowloader={true} />}>
            <Routes>
                <Route exact path="/home" element={<Protected><Home /></Protected>} />
                <Route exact path="/forgotpassword" element={<Forgot />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/" element={<Welcome />} />
                <Route exact path="/topics" element={<Protected><Topics /></Protected>} /><Route exact path="/topics/videos" element={<Protected><Videos /></Protected>} />
                <Route exact path="/topics/pdfs" element={<Protected><PDFs /></Protected>} />
                <Route exact path="/topics/glossary" element={<Protected><Glossary /></Protected>} />
                <Route exact path="/favorites" element={<Protected><Favorites /></Protected>} />
                <Route exact path="/account" element={<Protected><Account /></Protected>} />
                <Route exact path="/account/contactus" element={<Protected><Contactus /></Protected>} />
                <Route exact path="/account/myprofile" element={<Protected><MyProfile /></Protected>} />
                <Route exact path="/account/changepassword" element={<Protected><ChangePassword /></Protected>} />
                {AuthService.canDoCertification ?
                    <>
                        <Route exact path="/learning" element={<Protected><MyLearning /></Protected>} />
                        <Route exact path="/learning/video/:sessionid/:groupid" element={<Protected><Video /></Protected>} />
                        <Route exact path="/learning/quiz/:quizid/:groupid" element={<Protected><Quiz /></Protected>} />
                        <Route exact path="/learning/ojtform/:id/:groupid" element={<Protected><OjtForm /></Protected>} />
                        <Route exact path="/learning/manufacturer/:id/:type" element={<Protected><Manufacturer /></Protected>} />
                        <Route exact path="/learning/level/:levelid/:groupid/:type" element={<Protected><Level /></Protected>} />
                        <Route exact path="/learning/ojtpending/:id/:groupid" element={<Protected><OjtPending /></Protected>} />
                    </>
                    : ""}
                <Route path='*' element={<NotFound />}></Route>
            </Routes>
        </Suspense>
    );
}

