const STORAGE_KEY = "__USERDATA";
const ACCESS_TOKEN = "__ACCESSTOKEN";
import { ModalManager } from "@mui/material";
import CommonService from "./CommonService";
let userData;
const AuthService = {
    isAuthenticated: false,
    IsUserLoggedIn() {
        if (localStorage.getItem(STORAGE_KEY) === null && localStorage.getItem(ACCESS_TOKEN) === null) {
            return this.isAuthenticated = false;
        }
        else {
            return this.isAuthenticated = true;
        }
    },
    getUserData() {
        if (userData) {
            return userData || {};
        } else {
            var data = JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");
            userData = data;
            return data;
        }
    },
    getUserId() {
        return this.getUserData().userId;
    },
    getUserName() {
        return this.getUserData().userName;
    },
    getFirstName() {
        return this.getUserData().firstName;
    },
    getLastName() {
        return this.getUserData().lastName;
    },
    getBrowser() {
        var data = localStorage.getItem('__BROWSERNAME');
        return data;
    },
    getOSName() {
        var data = localStorage.getItem('__OSNAME');
        return data;
    },
    canDoCertification() {
        return this.getUserData().canDoCertification;
    },
    getToken() {
        return localStorage.getItem('__ACCESSTOKEN');
    },
    signIn(username, password, success, fail, callback) {
        this.signOut()
        fetch(CommonService.ServerUrl() + 'api/authenticate', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ UserName: username, Password: password, OS: this.getOSName() })
        }).then(res => res.json())
            .then(res => {
                if (res.errors.length > 0) {
                    if (callback) callback(res);
                } else {
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(res?.userData));
                    localStorage.setItem(ACCESS_TOKEN, res?.token);
                    if (callback) callback(res);
                    this.isAuthenticated = true;
                    this.IsUserLoggedIn();
                    return res;
                }
            })
            .catch(res => {
            });
    },
    signOut() {
        userData = null;
        localStorage.clear();
        this.isAuthenticated = false;
    },
    async forgotPassword(username, callback) {
        return await fetch(CommonService.ServerUrl() + 'api/authenticate/changepassword?username=' + username).then(async res => {
            if (callback) {
                callback(await res.json());
            }
        }).catch((res) => {
            if (res.message === "Unexpected token < in JSON at position 0") {
                window.location.href = "/forgotpassword";
            }
            if (callback) {
                callback(res);
                //return res;
            }
        });
    },
    async changePassword(username, password, callback) {
        return await fetch(CommonService.ServerUrl() + 'api/authenticate/changepassword', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: username, password: password })
        }).then(async res => {
            if (callback) {
                callback(await res.json());
            }
        }).catch((res) => {
            if (res.message === "Unexpected token < in JSON at position 0") {
                window.location.href = "/forgotpassword";
            }
            if (callback) {
                callback(res);
            }
        });
    },
    async updateProfile(firstname, lastname, callback) {
        var name = this.getUserName();
        var token = this.getToken();
        return await fetch(CommonService.ServerUrl() + 'api/authenticate/updateprofile?username=' + name + '&firstname=' + firstname + '&lastname=' + lastname, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            if (callback) {
                const profile = JSON.parse(localStorage.getItem(STORAGE_KEY));
                let profiledata = { 'firstName': firstname, 'lastName': lastname };
                Object.keys(profiledata).forEach((key) => {
                    profile[key] = profiledata[key];
                });
                localStorage.setItem(STORAGE_KEY, JSON.stringify(profile));
                callback(await res.json());
            }
        }).catch((res) => {
            if (res.message === "Failed to fetch") {
                localStorage.clear();
                window.location.href = "/login";
            }
            if (callback) {
                callback(res);
            }
        });
    },
    // hardRefresh() {
    //     userData = null;
    //     localStorage.removeItem(STORAGE_KEY);
    // },
};
export default AuthService;