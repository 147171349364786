import { ThemeContext } from '@emotion/react';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 321,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        type: 'light',
        primary: {
            light: '#DAE8EB',
            main: '#011352',
            dark: '#00002a',
        },
        secondary: {
            light: '#74f0ff',
            main: '#35BDD1',
            dark: '#008ca0',
        },
        background: {
            paper: '#EDEDED',
            white: '#fff',
            black: '#000'
        },
        other: {
            gray: '#DADADA',
            darkgray: '#707070'
        },
        mix: {
            gradient: 'linear-gradient(180deg, rgba(90,195,178,1) 0%, rgba(210,222,62,1) 100%);'
        }
    },

    typography: {
        fontFamily: ['"omnes-pro"', '"Segoe UI"', 'sans-serif'].join(','),
        h1: {
            fontSize: "2.125rem", //34PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h2: {
            fontSize: "2rem", //32PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h3: {
            fontSize: "1.875rem", //30PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h4: {
            fontSize: "1.75rem", //28PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h5: {
            fontSize: "1.5rem", //24PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h6: {
            fontSize: "1.375rem", //22PX
            fontWeight: 'bold',
            lineHeight: 1
        },
        subtitle1: {
            fontSize: "1.25rem" //20PX
        },
        subtitle2: {
            fontSize: "1.125rem" //18PX
        }
    },

    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    letterSpacing: 1,
                    '&.anchor-link': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    },
                    "&[data-error]": {
                        color: "#FF0000",
                        letterSpacing: 0,
                        lineHeight: 1.3,
                        fontSize: '0.875rem',
                    },
                    '&.backbtn': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#011352',
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    '&[data-round="true"]': {
                        minWidth: 250,
                        borderRadius: 26,
                        fontSize: 20,
                        fontWeight: 600,
                        padding: '4px 22px'
                    },
                    '&[data-round-sm="true"]': {
                        minWidth: 'auto',
                        borderRadius: 26,
                        fontSize: 20,
                        fontWeight: 600,
                        padding: '4px 22px'
                    },
                    '&.MuiButton-outlined': {
                        border: '2px solid #011352'
                    },
                    '&.danger': {
                        backgroundColor: '#f76464'
                    }
                },
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    marginTop: 0,
                    width: '100%',
                    marginBottom: 24,
                    '& .MuiFormControl-root': {
                        marginBottom: 0
                    },
                    '& .MuiFormLabel-root': {
                        color: "#011352",
                        fontSize: "1.25rem",
                        fontWeight: 600
                    },
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#FFF',
                        minHeight: 50,
                        '& input, textarea': {
                            fontSize: '1.25rem'
                        },
                        '& fieldset': {
                            borderColor: "#011352!important",
                            border: '2px solid',
                        },
                        '&.Mui-error': {
                            '& fieldset': {
                                borderColor: '#FF0000!important'
                            }
                        },
                        '& .MuiSelect-select': {
                            fontSize: "1.25rem"
                        }
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: '#FF0000',
                        letterSpacing: 0,
                        lineHeight: 1.3,
                    }
                },
            },
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    margin: 0
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-list': {
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }
            }
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ddd',
                    '&.Mui-selected': {
                        backgroundColor: '#011352!important',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#011352',
                        }
                    }
                }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        flex: 1,
                        textTransform: 'none',
                        color: '#194242',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#194242',
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: '0',
                    '& .MuiPaper-root': {
                        padding: '6px 0',
                        backgroundColor: '#fff',
                        borderBottom: '1px solid',
                        borderColor: '#DADADA',
                        margin: 0,
                        '& .MuiAccordionSummary-root': {
                            padding: 0,
                            '& .MuiAccordionSummary-content': {
                                '& svg': {
                                    marginRight: 10
                                },
                                '&.Mui-expanded': {
                                    margin: '8px 0'
                                }
                            },
                            '&.Mui-expanded': {
                                minHeight: 45
                            }
                        },
                        '&::before': {
                            height: 0
                        }
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    color: '#194242',
                    '&.Mui-expanded': {
                        margin: 0
                    },
                    '& .MuiTypography-body1': {
                        fontSize: '0.875rem'
                    },
                    '& .MuiAccordionDetails-root': {
                        padding: 0,
                        '& div': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 0',
                            cursor: 'pointer'
                        },
                        '& .MuiTypography-body2': {
                            flex: 2,
                            marginLeft: 20
                        }
                    }
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    '& svg': {
                        fill: '#fff'
                    },
                    '& > div:first-of-type': {
                        backgroundColor: '#011352',
                        margin: 0,
                        color: '#fff',
                        minHeight: 40,
                        maxHeight: 40
                    },
                    '& .MuiPickersDay-root': {
                        width: 42,
                        height: 40,
                        fontSize: '1rem',
                        '&.MuiPickersDay-today:not(.Mui-selected)': {
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#011352',
                            border: '2px solid #011352',
                            backgroundColor: '#fff'
                        }
                    },
                    '& .MuiTypography-caption': {
                        width: '100%',
                        height: 30,
                        fontWeight: 600,
                        backgroundColor: '#d1d1d1',
                        margin: 0,
                        borderBottom: '1px solid #011352',
                        fontSize: '1rem'
                    }
                }
            }
        }
    },
});
